exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-react-95-gatsby-theme-src-components-desktop-js": () => import("./../../../node_modules/@react95/gatsby-theme/src/components/desktop.js" /* webpackChunkName: "component---node-modules-react-95-gatsby-theme-src-components-desktop-js" */),
  "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-bolacha-acafrao-mdx": () => import("./../../../node_modules/@react95/gatsby-theme/src/components/desktop.js?__contentFilePath=/home/runner/work/95Recipes/95Recipes/content/bolacha-acafrao.mdx" /* webpackChunkName: "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-bolacha-acafrao-mdx" */),
  "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-enchiladas-de-pepino-mdx": () => import("./../../../node_modules/@react95/gatsby-theme/src/components/desktop.js?__contentFilePath=/home/runner/work/95Recipes/95Recipes/content/enchiladas-de-pepino.mdx" /* webpackChunkName: "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-enchiladas-de-pepino-mdx" */),
  "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-macarrao-molho-de-cenoura-mdx": () => import("./../../../node_modules/@react95/gatsby-theme/src/components/desktop.js?__contentFilePath=/home/runner/work/95Recipes/95Recipes/content/macarrao-molho-de-cenoura.mdx" /* webpackChunkName: "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-macarrao-molho-de-cenoura-mdx" */),
  "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-pao-de-banana-mdx": () => import("./../../../node_modules/@react95/gatsby-theme/src/components/desktop.js?__contentFilePath=/home/runner/work/95Recipes/95Recipes/content/pao-de-banana.mdx" /* webpackChunkName: "component---node-modules-react-95-gatsby-theme-src-components-desktop-js-content-file-path-content-pao-de-banana-mdx" */),
  "component---node-modules-react-95-gatsby-theme-src-pages-404-js": () => import("./../../../node_modules/@react95/gatsby-theme/src/pages/404.js" /* webpackChunkName: "component---node-modules-react-95-gatsby-theme-src-pages-404-js" */)
}

