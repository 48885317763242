module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@react95/gatsby-theme/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"95 Recipes","short_name":"95Recipes","theme_color":"#000e7a","background_color":"#55aaaa","display":"standalone","orientation":"portrait","description":"Recipes in a vintage way","start_url":"/","icon":"static/images/icons/icon-512x512.png","cache_busting_mode":"none","screenshots":[{"src":"/images/screenshots/home.png","sizes":"1080x1920","label":"Consulte as mais deliciosas receitas","type":"image/png"},{"src":"/images/screenshots/full_recipe.png","sizes":"1080x1920","label":"Veja os ingredientes e como fazer","type":"image/png"},{"src":"/images/screenshots/picture.png","sizes":"1080x1920","platform":"windows","label":"Confira o resultado final","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
